import { permissions } from '@mpk/shared/domain';

/**
 * Permissions for the addresses management
 */
enum AddressesPermissionsEnum {
  None = 0,

  /**
   * Manage addresses
   */
  ManageAllAddresses = 1,
}

export const AddressesPermissions = permissions(AddressesPermissionsEnum, {
  featureId: 'Addresses',
});
