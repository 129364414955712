<ng-container *mpHeaderElement="'left'">
  <mp-root-module-nav label="Adressen" />
</ng-container>

<mp-overview moduleTitle="Adressen" [resultCount]="addresses().length" (search)="fetchAddresses($event)">
  <button mat-menu-item routerLink="create">Neu</button>

  @if (addressesLoaded()) {
    <cdk-virtual-scroll-viewport itemSize="100">
      <mp-overview-item *cdkVirtualFor="let address of addresses(); trackBy: trackByFn" [path]="address.addressId">
        <h2>{{ address.street }}</h2>
        <div>{{ address.zipCode }}, {{ address.city }}</div>
      </mp-overview-item>
    </cdk-virtual-scroll-viewport>
  } @else {
    <mp-spinner />
  }
</mp-overview>
